<template>
  <div class="editorIPBGView">
    <div class="content">
      <ul class="contentUl">
        <li class="contentLi" @click="pushInPut('all', '单点', baseUrl, 'baseUrl')">
          <cell_title_detail head-title="单点" :content-title="baseUrl"></cell_title_detail>
        </li>
        <li class="contentLi" @click="pushInPut('all', '资产', assetsUrl, 'assetsUrl')">
          <cell_title_detail head-title="资产" :content-title="assetsUrl"></cell_title_detail>
        </li>
      </ul>
      <div class="sureButton" @click="saveData">确定</div>
    </div>

    <in-put @updateInfo="updateInfo" v-if="showEditor" :in-put-info="inPutInfo"></in-put>

  </div>
</template>

<script>
import Cell_title_detail from '@/components/cell/cell_title_detail/cell_title_detail'
import InPut from '@/components/inPut/inPut'

export default {
  name: 'editorIP',
  components: { InPut, Cell_title_detail },
  data() {
    return {
      baseUrl: window.ipConfig.VUE_APP_BASEURL,
      assetsUrl: window.ipConfig.VUE_APP_ASSETURL,
      IP: window.ipConfig.VUE_APP_IP,
      basePort: '10000',
      assetsPort: '8090',
      showEditor: false,
      inPutInfo: {}
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'login') {
      next(true)
      return;
    }
    if (this.showEditor) {
      this.showEditor = false;
      next(false);
      return;
    }
    next(true);
  },
  mounted() {
    this.$nextTick(() => {

    })
  },
  methods: {
    saveData: function() {
      window.ipConfig.VUE_APP_ASSETURL = this.assetsUrl
      window.ipConfig.VUE_APP_BASEURL = this.baseUrl;
      localStorage.setItem('ipConfig', JSON.stringify(window.ipConfig))
      this.$message.success('修改成功')
    },
    updateInfo: function(dataInfo) {
      for (const key in dataInfo) {
        this[key] = dataInfo[key]
      }
      this.showEditor = false
    },
    pushInPut: function(matchType, headTitle, content, dataKey) {
      this.showEditor = true
      this.inPutInfo = {
        headTitle: headTitle,
        contentTitle: content,
        matchType: matchType,
        dataKey: dataKey,
        inPutKey: 'setDataInfo'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.editorIPBGView {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;

  .content {
    width: 100%;
    margin-top: 10px;
    height: calc(70% - 10px);
    overflow: scroll;

    .contentUl {
      padding: 0;
      list-style-type: none;

      .contentLi {

      }

    }

  }

  .sureButton {
    margin-top: 10px;
    background-color: $color_primary;
    border-radius: 8px;
    width: 80%;
    height: 45px;
    transform: translate(12.5%, 0px);
    font-size: 20px;
    color: white;
    text-align: center;
    line-height: 45px;
  }

}

</style>
